export default function header(data) {
    return `
    <header>
    
       <!--<div class="site_logo">
            <a href="/">Rhizome</a>            
        </div>-->
        <!--<div id="btn_main_menu" class="btn_main_menu">
            <div></div>
        </div>-->
        
    </header>
   `;
};