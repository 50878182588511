export default function footer(data) {
    return `
    <footer>
    
        <div>
            <span></span>
            
          
        </div>
        
    </footer>
   `;
}