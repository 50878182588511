export default class Templates {
    static get() {
        return {
            location: {
                page_slug: {},
                route    : {}
            },
            config  : {}
        }
    }
}