import common from "./common";

export default function app() {
    return {
        "protocol_app": "https",
        "dns_app"     : common().dns,
        "url_assets"  : "https://rhizomiser.org/app/assets/dist",
        "port_app"    : ''
    };
}
