const search = (dropdown_data, doMultiNodesSearch) => {
    const search_bar_controls_class = (doMultiNodesSearch) ? "multi_nodes_search" : "uniq_node_search";
    const do_search_button_HTML = (doMultiNodesSearch) ?
        `
            <div id="search_bar__do_search">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
            </div>
        `
        :
        "";

    return `
        <div class="search_bar_error" id="search_bar_error">
            Saisissez au moins un noeud à chercher
        </div>
        <div class="search_bar">
            <div id="search_bar__contenteditable" contenteditable="true" class="search_bar__contenteditable" spellcheck="false">
                ${placeholder()}
            </div>
            <div class="search_bar__controls ${search_bar_controls_class}" id="search_bar__controls">
                <div id="search_bar__close">
                    ${get_remove_icon()}
                </div>
                ${do_search_button_HTML}
            </div>
            <section id="search_bar__selected_nodes" class="search_bar__selected_nodes">
                <div id="search_bar__selected_nodes__open_section_button" class="search_bar__selected_nodes__open_section_button">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM297 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L120 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l214.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L409 239c9.4 9.4 9.4 24.6 0 33.9L297 385z"/></svg>
                </div>
                <div id="search_bar__selected_nodes__close_section_button" class="search_bar__selected_nodes__close_section_button">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M512 256A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM215 127c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-71 71L392 232c13.3 0 24 10.7 24 24s-10.7 24-24 24l-214.1 0 71 71c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L103 273c-9.4-9.4-9.4-24.6 0-33.9L215 127z"/></svg>
                </div>
                <h1>Votre recherche</h1>
                <div class="search_bar__selected_nodes__list" id="search_bar__selected_nodes__list"></div>
            </section>
            ${dropdown(dropdown_data)}
        </div>
    `;
}

const placeholder = () => {
    return `
        <span id="search_bar_placeholder" class="search_bar__contenteditable__placeholder"><!--Chercher des nœuds. Ah! Pardon. Des lieux, des catégories ...--></span>
    `;
};

const dropdown = (data) => {
    return `
    <div id="dropdown" class="dropdown">
        <div id="dropdown_content" class="dropdown__content">
            ${data.map((item) => {
        return dropdown_list_item(item);
    }).join("")}
        </div>
    </div>
  `;
};

const dropdown_list_item = (item) => {
    return `
        <button spellcheck="false" data-node-name="${item}">
            ${item}
        </button>
    `;
}

const selected_node_in_selected_nodes_section = (node_name) => {
    return `
        <div spellcheck="false" class="selected_node_to_search" data-node-name="${node_name}">
            ${node_name}
            <span class="selected_node_to_search__remove">
                ${get_remove_icon()}    
            </span>
        </div>
    `;
}

const get_remove_icon = () => {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
    `;
}

export {
    search,
    placeholder,
    selected_node_in_selected_nodes_section
}