import Menu from "../Controllers/Menu";

export default class Listeners {
    init() {
        //this.do_toggle_menu();
    }

    do_toggle_menu() {
        document.getElementById('btn_main_menu').addEventListener('click', function () {
            if (document.getElementById("menu").classList.contains("open_menu")) {
                Menu.close_menu();
            } else {
                Menu.open_menu();
            }
        }, false);
    }

    destroy() {

    }
}