import {Route, Di} from "hind/core";
import Entities from "../Models/Entities";
import tpl_home from "../views/routes/home";

const config = {
    meta: {}
};

export default class Home extends Route {
    constructor(args) {
        args = {
            Ourl_info: args.Ourl_info
        };

        super(args, config);
    }

    get_content_data() {
        return {
            view: tpl_home()
        };
    }

    get_content_data_first_loading_from_client() {
        return {};
    }

    run_after_rendered_view() {
        Di.get_di("Hierarchy_tree").run("#hierarchy_tree_wrapper", Entities).then(
            is_running => {
                const oSearch_bar = Di.get_di("Hierarchy_tree").get_search_bar_instance();

                document.getElementById("hierarchy_tree_wrapper").insertAdjacentHTML("beforebegin", oSearch_bar.get_view());

                oSearch_bar.run();
            }
        );
    }
}