export default class Menu {

    static open_menu() {
        //   console.log('open_menu');
        document.getElementById('menu').classList.add('open_menu');
    }

    static close_menu() {
        //console.log("close_menu");
        document.getElementById('menu').classList.remove('open_menu');
    }

}