export default class Front {
    static get_launcher() {
        return {};
    }

    static get_modules() {
        return {
            front_firstly: [],
            front        : [
                {
                    module: () => {
                        return import("d3");
                    },
                    di_key: "D3"
                },
                {
                    module          : () => {
                        return import("../../Modules/Hierarchy_tree/Hierarchy_tree");
                    },
                    di_key          : "Hierarchy_tree",
                    is_a_hind_module: true,
                    config          : {
                        search: {
                            doMultiNodesSearch: false
                        }
                    }
                }
            ]
        };
    }
}